import './classic/jquery.min';
import './classic/cookie';
import './libraries/slimmageSettings';
import './libraries/slimmage';


// App
import App from './app';
import PolyfillController from './controllers/polyfill';

import HeaderController from './controllers/header';

(async () => {
    // document.querySelector('html').classList.remove('no-js');

    const polyfills = new PolyfillController();
    await polyfills.init();

    try {
        const app = new App();
        app.resizeThrottle = 10;
        app.scrollThrottle = 100;
        window.app = app;
    
        app.polyfillController = polyfills;

        app.headerController = new HeaderController();

        // Modules
        const loadModules = () => {
            console.log(`Loading modules`);

            // Example of how to load a module
            // if (document.querySelector('.shareContainer')) {
            //     import( /* webpackChunkName: "modules/share" */ './controllers/share/controller').then(e => {
            //         app.shareController = new e.default();
            //     });
            // }

            if (document.querySelector('.shareContainer')) {
                import( /* webpackChunkName: "modules/share" */ './controllers/share/controller').then(e => {
                    app.shareContainer = new e.default();
                });
            }

            if (document.querySelector('.splide')) {
                import( /* webpackChunkName: "modules/slider" */ './controllers/slider').then(e => {
                    app.sliderController = new e.default();
                });
            }

            if (document.querySelector('.formWrapper form')) {
                import( /* webpackChunkName: "modules/form" */ './controllers/form').then(e => {
                    app.formController = new e.default();
                });
            }
        };

        loadModules();

        await app.init();
        // app.insertTesters();
    } catch(e) {
        console.error(`Something went wrong initializing the app`);
        throw e;
    };

    console.log(`App initialized`);
    // document.documentElement.classList.remove('preventAnimations');

})();
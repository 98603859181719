export default class HeaderController {
	constructor() {
		this.headerElement = null;
		this.menuButton = null;

		this.init();
	}

	init() {
		this.headerElement = document.querySelector('header');
		this.menuButton = this.headerElement.querySelector('.menuButton');

		// Click events
		this.menuButton.addEventListener('click', this.toggleMenu.bind(this));

		window.addEventListener('keydown', e => {
			if (e.key.substr(0, 3).toLowerCase() == 'esc') {
				this.closeMenu();
			}
		});
	}

	toggleMenu() {
		document.body.classList.toggle('mobileMenuActive');
	}

	closeMenu() {
		document.body.classList.remove('mobileMenuActive');
	}
}